import { useCallback, useContext } from 'react';

import { ModalDispatchContext } from '../context/modalContext';

export const useGetAppOrSignUpHandler = () => {
  const setModalState = useContext(ModalDispatchContext);

  return useCallback(() => {
    setModalState({ signupVisible: true });
  }, [setModalState]);
};
